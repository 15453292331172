define('ember-intercom-io/components/intercom-io', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      get = _ember.default.get,
      inject = _ember.default.inject;
  exports.default = Component.extend({
    intercom: inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'intercom').start();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      get(this, 'intercom').stop();
    }
  });
});