define('ember-clock/initializers/clock', ['exports'], function (exports) {
  exports.initialize = initialize;
  /*
    Inject the clock service into all controllers and components
  */

  function initialize() {
    var app = arguments[1] || arguments[0];
    app.inject('controller', 'clock', 'service:clock');
    app.inject('component', 'clock', 'service:clock');
  }

  exports['default'] = {
    name: 'clock',
    initialize: initialize
  };
});