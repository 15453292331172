define('ember-initials/services/ember-initials-store', ['exports', 'ember', 'object-hash', 'ember-initials/utils/generators/svg'], function (exports, _ember, _objectHash, _svg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      Service = _ember.default.Service;
  exports.default = Service.extend({
    initials: {},

    generator: computed(function () {
      return new _svg.default();
    }),

    removeAll: function removeAll() {
      var _this = this;

      Object.keys(this.get('initials')).forEach(function (key) {
        return _this.get('generator').revoke(key);
      });
      this.set('initials', {});
    },
    initialsFor: function initialsFor(properties) {
      var key = (0, _objectHash.default)(properties);
      return this.get('initials')[key] || this._create(key, properties);
    },
    _create: function _create(key, properties) {
      return this.get('initials')[key] = this.get('generator').generate(properties);
    }
  });
});