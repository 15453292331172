define('ember-initials/mixins/adorable', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Mixin.create({
    tagName: 'img',
    attributeBindings: ['width', 'height', 'src', 'title', 'alt'],

    email: '',
    image: '',

    title: 'User Avatar',
    alt: 'User Avatar',

    size: 30,
    height: _ember.default.computed.reads('size'),
    width: _ember.default.computed.reads('size'),

    src: _ember.default.computed.or('image', 'adorable'),

    adorable: _ember.default.computed('email', 'size', function () {
      return 'https://api.adorable.io/avatars/' + this.get('size') + '/' + this.get('email');
    })
  });
});