define('ember-initials/mixins/image', ['exports', 'ember', 'ember-initials/config'], function (exports, _ember, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Mixin.create({
    tagName: 'img',
    attributeBindings: ['width', 'height', 'src', 'title', 'alt'],

    image: null,
    defaultImage: _ember.default.computed.reads('config.image.defaultImageUrl'),

    title: 'User Avatar',
    alt: 'User Avatar',
    size: 30,
    height: _ember.default.computed.reads('size'),
    width: _ember.default.computed.reads('size'),

    src: _ember.default.computed.or('image', 'defaultImage'),

    config: _ember.default.computed(function () {
      var appSettings = _ember.default.getOwner(this).resolveRegistration('config:environment').emberInitials || {};
      return _ember.default.assign(_config.default, appSettings);
    })
  });
});