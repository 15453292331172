define('ember-initials/mixins/initials', ['exports', 'ember', 'ember-initials/utils/color-index', 'ember-initials/utils/initials'], function (exports, _ember, _colorIndex, _initials) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Mixin.create({
    tagName: 'img',
    attributeBindings: ['width', 'height', 'src', 'onError'],

    initialsStore: _ember.default.inject.service('ember-initials-store'),

    defaultName: '?',
    defaultBackground: '#dd6a58',

    image: null,
    name: _ember.default.computed.reads('defaultName'),
    seedText: _ember.default.computed.reads('name'),

    size: 30,
    height: _ember.default.computed.reads('size'),
    width: _ember.default.computed.reads('size'),

    backgroundStyles: {},
    textStyles: {},

    textColor: 'white',
    fontSize: 50,
    fontWeight: 200,
    fontFamily: 'Helvetica Neue Light, Arial, sans-serif',

    colors: ['#1abc9c', '#16a085', '#f1c40f', '#f39c12', '#2ecc71', '#27ae60', '#e67e22', '#d35400', '#3498db', '#2980b9', '#e74c3c', '#c0392b', '#9b59b6', '#8e44ad', '#bdc3c7', '#34495e', '#2c3e50', '#95a5a6', '#7f8c8d', '#ec87bf', '#d870ad', '#f69785', '#9ba37e', '#b49255', '#b49255', '#a94136', '#5461b4'],

    initialsObserver: _ember.default.observer('name', 'seedText', 'fontSize', 'fontWeight', 'fontFamily', 'textColor', 'defaultName', function () {
      this.notifyPropertyChange('src');
    }),

    backgroundColor: _ember.default.computed('colors.length', 'seedText', 'defaultName', 'defaultBackground', function () {
      if (this.get('seedText') === this.get('defaultName')) {
        return this.get('defaultBackground');
      } else {
        var index = (0, _colorIndex.default)(this.get('seedText'), this.get('colors.length'));
        return this.get('colors')[index];
      }
    }),

    src: _ember.default.computed('fastboot.isFastBoot', 'image', function () {
      var image = this.get('image');

      if (!this.get('fastboot.isFastBoot')) {
        return image ? image : this.createInitials();
      } else {
        return image ? image : '';
      }
    }),

    fastboot: _ember.default.computed(function () {
      return _ember.default.getOwner(this).lookup('service:fastboot');
    }),

    onError: _ember.default.computed('image', function () {
      if (this.get('image')) {
        return this._checkImage.bind(this);
      }
    }),

    createInitials: function createInitials() {
      return this.get('initialsStore').initialsFor(this.initialsProperties());
    },
    initialsProperties: function initialsProperties() {
      return {
        width: 100,
        height: 100,
        initials: (0, _initials.default)(this.get('name') || this.get('defaultName')),
        initialsColor: this.get('textColor'),
        textStyles: _ember.default.assign(this._textStyles(), this.get('textStyles')),
        backgroundStyles: _ember.default.assign(this._backgroundStyles(), this.get('backgroundStyles'))
      };
    },
    _textStyles: function _textStyles() {
      return {
        'font-family': this.get('fontFamily'),
        'font-weight': this.get('fontWeight'),
        'font-size': this.get('fontSize') + 'px'
      };
    },
    _backgroundStyles: function _backgroundStyles() {
      return {
        'background-color': this.get('backgroundColor')
      };
    },
    _checkImage: function _checkImage(e) {
      e.srcElement.src = this.createInitials();
    }
  });
});